.job {
    text-align: left;
    border-radius: 15px;
    max-width: 800px;
    width: 80vw;
    padding: 15px 30px;
    margin-bottom: 50px;
    background-color: #ffffff;
    min-height: 215px;
}

.subJob {
    text-align: left;
    border-radius: 15px;
    max-width: 700px;
    width: 70vw;
    padding: 15px 30px;
    margin-bottom: 30px;
    background-color: #ffffff;
}

.job-logo {
    width: 100%;
    max-width: 200px;
    height: fit-content;
    border-radius: 15px;
    object-fit: contain;
}

.job-title {
    margin-top: 0px;
    margin-bottom: 0rem;
    font-size: 1.5rem;
}

.job-position {
    margin-top: 0px;
    margin-bottom: 0rem;
    font-size: 1.25rem;
}

.job-date {
    margin-top: 0px;
    margin-bottom: 0rem;
    font-size: 1rem;
}
.job-location {
    margin-top: 0px;
    margin-bottom: 0rem;
    font-size: 1rem;
    font-style: italic;
}

.job-detail-list {
    font-size: 1rem;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0 0 0 20px;
}

.job-detail {
    margin-top: 0px;
    margin-bottom: 0.5rem;
}

.cascade {
    transform: translateX(-10px) translateY(0px);
}

@keyframes fadeIn {
    0% {
        /* visibility: hidden; */
        opacity: 0;
        max-height: 0%;
    }
    50% {
        /* visibility: hidden; */
        opacity: 0;
    }
    100% {
        /* visibility: visible; */
        opacity: 1;
        max-height: 100%;
    }
}

@keyframes fadeOut {
    0% {
        /* visibility: hidden; */
        opacity: 1;
    }
    50% {
        /* visibility: hidden; */
        opacity: 0;
    }
    100% {
        /* visibility: visible; */
        opacity: 0;
    }
}

/* Initially hide the sub-experience container */
.sub-experience-container {
    display: flex;
    max-height: 0px;
}

.multiJobs {
    transition: transform 0.1s;
}

.multiJobs:hover {
    cursor: pointer;
    transform: scale(102%);
}
