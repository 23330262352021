.home-page {
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #292929;
    background-color: white;
    /* background-color: #f6f6f6; */
}

.line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin: 20px 0px 10px 0px;
}

.home-buttons {
    margin: 0;
    padding: 0;
    min-width: 0;
    margin-right: 15px;
    border: 1px solid #292929;
}

.contact-button {
    color: #585858 !important;
}

.contact-button:hover {
    color: #47bb73 !important;
}

/* Scroll down chevrons / arrows */
.arrow {
    opacity: 0;
    position: absolute;
    left: calc(50% - 10px);
    top: 75vh;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.arrow-1 {
    -webkit-animation: arrow-movement 2s ease-in-out infinite;
    animation: arrow-movement 2s ease-in-out infinite;
}

.arrow-2 {
    -webkit-animation: arrow-movement 2s 1s ease-in-out infinite;
    animation: arrow-movement 2s 1s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
    background: #000000;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
}

.arrow:before {
    -webkit-transform: rotate(45deg) translateX(-23%);
    transform: rotate(45deg) translateX(-23%);
    -webkit-transform-origin: top left;
    transform-origin: top left;
}

.arrow:after {
    -webkit-transform: rotate(-45deg) translateX(23%);
    transform: rotate(-45deg) translateX(23%);
    -webkit-transform-origin: top right;
    transform-origin: top right;
}

@-webkit-keyframes arrow-movement {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        top: 80vh;
    }
}

@keyframes arrow-movement {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        top: 80vh;
    }
}
