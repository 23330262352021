.exp-page {
    background-color: #f6f6f6;
    min-height: 90vh;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #292929;
}
