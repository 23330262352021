.project {
    text-align: left;
    border-radius: 10px;
    padding: 15px 15px;
    margin: 1px;
    margin-bottom: 50px;
    width: 75vw;
    max-width: 1000px;
    box-shadow: none;
    transition: box-shadow 0.5s;
}

.project:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.project-logo {
    border-radius: 5px;
    width: 100%;
    height: auto;
    max-height: 265px;
    object-fit: cover;
}

.project-detail {
    font-size: 1rem;
}

.porject-urls {
    text-decoration: none;
    color: inherit;
}
